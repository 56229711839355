.scholarship {
  margin-top: 1.5rem;
  width: 100%;
  display: flex;
  /* align-items: center; */
  /* height: 110px; */
  /* border: 1px solid red; */
  /* justify-content: space-around;
  flex-direction: column; */
  justify-content: center;
  gap: 4rem;
  flex-wrap: wrap;

  margin-bottom: 100px;
}

.app {
  display: flex;
  justify-content: center;
}

/* .apply {
  color: #ffffff;
  max-width: 300px;
  background-color: #ff8533;
  padding: 0.5rem 1rem;
  box-sizing: border-box;
  margin-top: 0.8rem;
  /* margin-left: 30px;
  margin-right: 30px; */
/* border-radius: 100px;
  border: 1px solid #ff822e;
  font-weight: bold; */
/* font-size: clamp(0.5rem, 0.8rem 10vw, 1.5rem); */
/* display: flex;
  justify-content: center;
  margin: 0 0.5rem;
  align-items: center; */
/* }  */

.apply {
  max-width: 40%;
  display: flex;
  /* justify-content: center; */
  text-align: center;
  background-color: #ff8533;
  text-align: center;
  border-radius: 10px;
  font-size: 120%;
  font-weight: bold;
  color: white;
  padding: 1rem 2rem;
  cursor: pointer;
  display: flex;
  justify-content: center;
  border: none;
}

.paystack {
  background-color: #ff8533;
  padding: 1rem 1rem;
  border-radius: 30px;
  color: white;
  font-weight: bold;
  font-size: clamp(0.3rem, 1rem + 20vw, 1rem);
  /* margin-right: 0.6rem; */
}

.parent-paystack {
  margin-left: -10px;
  max-width: 150%;
  min-height: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.one,
.two {
  height: 200px;
  width: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.list {
  margin-left: -35px;
}

h4 {
  display: flex;
  gap: 70px;
}

li {
  list-style-type: none;
}

.pa {
  margin-top: 10px;
}

.list-two {
  margin-top: -2px;
}

@media only screen and (max-width: 600px) {
  .list {
    padding-left: 30px;
    padding-right: 5px;
  }
  .apply-one {
    font-size: 65%;
    font-weight: bold;

    padding: 1px 1rem;
    width: 90%;
  }

  .apply {
    max-width: 45%;
  }

  .app {
    padding-left: 15px;
    margin-bottom: 30px;
  }
}

@media only screen and (max-width: 500px) {
  .apply-one {
    font-size: 90%;
    font-weight: bold;

    padding: 2px 1rem;
    width: 90%;
  }

  .apply {
    max-width: 55%;
  }
}

@media only screen and (max-width: 414px) {
  .app {
    padding-left: 27px;
  }
}
